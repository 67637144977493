<template>
  <form>
    <div class="animated fadeIn">
      <b-card class="px-md-2">
        <ResponseAlert ref="response" />
        <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
        <b-row class="mt-2">
          <b-col
            lg="9"
            md="11"
          >
            <b-row
              class="my-1"
            >
              <b-col sm="3">
                <label
                  class="h5 font-weight-light"
                  for="name"
                >No Order</label>
              </b-col>
              <b-col sm="7">
                <h5
                  class="font-weight-bold"
                >{{ models.noOrder }}</h5>
              </b-col>
            </b-row>
            <b-row
              class="my-1"
            >
              <b-col sm="3">
                <label
                  class="h5 font-weight-light"
                  for="name"
                >Status</label>
              </b-col>
              <b-col sm="7">
                <h5
                  class="font-weight-bold"
                >{{ models.status }}</h5>
              </b-col>
            </b-row>
            <b-row
              class="my-1"
            >
              <b-col sm="3">
                <label
                  class="h5 font-weight-light"
                  for="name"
                >Alasan</label>
              </b-col>
              <b-col sm="7">
                <h5
                  class="font-weight-bold"
                >{{ models.reason }}</h5>
              </b-col>
            </b-row>
            <hr>
            <b-row
              class="my-1"
            >
              <b-col sm="3">
                <label
                  class="h5 font-weight-light"
                  for="name"
                >Nama Customer</label>
              </b-col>
              <b-col
                sm="7"
              >
                <h5
                  class="font-weight-bold"
                >{{ models.customerName }}</h5>
              </b-col>
            </b-row>
            <b-row
              class="my-1"
            >
              <b-col sm="3">
                <label
                  class="h5 font-weight-light"
                  for="name"
                >Treatment</label>
              </b-col>
              <b-col sm="7">
                <h5
                  class="font-weight-bold"
                >
                  {{ models.treatments.length ? models.treatments.map(item => item.treatment.name).join(', ') : '-' }}
                </h5>
              </b-col>
            </b-row>
            <b-row
              class="my-1"
            >
              <b-col sm="3">
                <label
                  class="h5 font-weight-light"
                  for="name"
                >Staff</label>
              </b-col>
              <b-col sm="7">
                <h5
                  class="font-weight-bold"
                >{{ models.staff }}</h5>
              </b-col>
            </b-row>
            <b-row
              class="my-1"
            >
              <b-col sm="3">
                <label
                  class="h5 font-weight-light"
                  for="name"
                >Keluhan</label>
              </b-col>
              <b-col sm="7">
                <h5
                  class="font-weight-bold"
                >{{ models.notes }}</h5>
              </b-col>
            </b-row>
            <b-row
              class="my-1"
            >
              <b-col sm="3">
                <label
                  class="h5 font-weight-light"
                  for="name"
                >Harga</label>
              </b-col>
              <b-col sm="7">
                <h5
                  class="font-weight-bold"
                >
                  Rp. {{ parseFloat(models.totalPayment).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                </h5>
              </b-col>
            </b-row>
            <b-row
              class="my-1"
            >
              <b-col sm="3">
                <label
                  class="h5 font-weight-light"
                  for="name"
                >Durasi</label>
              </b-col>
              <b-col sm="7">
                <h5
                  class="font-weight-bold"
                >{{ diffTime }}</h5>
              </b-col>
            </b-row>
            <b-row
              class="my-1"
            >
              <b-col sm="3">
                <label
                  class="h5 font-weight-light"
                  for="name"
                >Waktu Booking</label>
              </b-col>
              <b-col sm="7">
                <h5
                  class="font-weight-bold"
                >
                  {{ moment(models.startDate).format('DD MMM YYYY, HH.mm') }} WIB</h5>
              </b-col>
            </b-row>
            <hr>
            <div
              v-if="models.products.length"
            >
              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5 font-weight-bold"
                    for="name"
                  >Produk</label>
                </b-col>
              </b-row>
              <b-row
                v-for="product, idx in models.products"
                :key="idx"
                class="my-1"
              >
                <b-col sm="6">
                  <label
                    v-if="product.product"
                    class="h5 font-weight-light"
                    for="name"
                  >{{ product.product.name }}</label>
                </b-col>
                <b-col sm="2">
                  <label
                    class="h5 font-weight-light"
                    for="name"
                  >{{
                    product.qty
                  }}</label>
                </b-col>
                <b-col sm="4">
                  <label
                    class="h5 font-weight-light"
                    for="name"
                  >
                    Rp. {{ (parseFloat(product.unitPrice) * parseFloat(product.qty)).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                  </label>
                </b-col>
              </b-row>
            </div>
            <hr>
            <b-row
              class="my-1"
            >
              <b-col sm="5">
                <label
                  class="h5 font-weight-bold"
                  for="name"
                >Metode Pembayaran</label>
              </b-col>
              <b-col sm="7">
                <label
                  class="h5 font-weight-bold"
                  for="name"
                >{{ paymentMethod }}</label>
              </b-col>
            </b-row>
            <b-row
              class="my-1"
            >
              <b-col sm="5">
                <label
                  class="h5 font-weight-bold"
                  for="name"
                >Total Pembayaran</label>
              </b-col>
              <b-col sm="7">
                <label
                  class="h5 font-weight-bold"
                  for="name"
                >
                  Rp. {{ parseFloat(models.totalPayment).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}

                </label>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          class="mt-5"
        >
          <b-col
            md="7"
          >
            <div class="d-flex justify-content-center justify-content-md-end">
              <b-button
                type="button"
                variant="light"
                class="btn-min-width rounded btn-secondary"
                @click="$router.push(config.uri)"
              >
                Kembali
              </b-button>
              <b-button
                v-if="models.statusNumber === 0"
                type="button"
                variant="light"
                class="btn-min-width btn-warning rounded mx-1"
                @click="rejectPO"
              >
                Tolak
              </b-button>
              <b-button
                v-if="models.statusNumber === 0"
                type="button"
                variant="primary"
                class="btn-min-width rounded"
                @click.prevent="acceptPO"
              >
                Terima
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </form>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import api from '@/utils/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'FormPurchaseOrder',

  metaInfo: {
    title: 'Form PO',
  },

  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },

  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        noOrder: '',
        status: '',
        reason: '',
        customerName: '',
        treatments: [],
        staff: '',
        notes: '',
        price: '',
        startDate: '',
        endDate: '',
        products: [],
        paymentMethod: '',
        totalPayment: '',
      },
    }
  },

  computed: {
    diffTime() {
      const totalMinutes = this.moment(this.models.endDate).diff(this.moment(this.models.startDate), 'minutes')
      const minutes = totalMinutes % 60
      const hours = Math.floor(totalMinutes / 60)

      let text = ''
      if (hours > 0) {
        text += `${hours} Jam`
      }
      if (minutes > 0) {
        text += ` ${minutes} Menit`
      }
      return text
    },
    paymentMethod() {
      return this.models.paymentMethod === 1 ? 'Tunai' : this.models.paymentMethod === 2 ? 'Debit' : 'Wallet'
    },
  },

  mounted() {
    this.getById()
  },

  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`invoice/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.models.id = data.id
            this.models.kfsId = data.kfsId
            this.models.salesId = data.salesId
            this.models.invoiceCode = data.invoiceCode
            this.models.totalInvoice = data.totalInvoice
            this.models.voucherCode = data.voucherCode
            this.models.discount = data.discount
            this.models.totalPayment = data.totalPayment
            this.models.paymentMethod = data.paymentMethod
            this.models.cashpay = data.cashpay
            this.models.change = data.change
            this.models.debitNumber = data.debitNumber
            this.models.phoneNumber = data.phoneNumber
            this.models.noOrder = data.sales.noOrder
            this.models.status = data.changeInvoiceStatusText
            this.models.statusNumber = data.changeInvoiceStatus
            this.models.reason = data.changeInvoiceReason
            this.models.customerName = data.sales.customer && (data.sales.customer.profile.firstName || '') + (data.sales.customer.profile.lastName || '')
            this.models.treatments = data.sales.treatments
            this.models.staff = data.sales.staff && (data.sales.staff.profile.firstName || '') + (data.sales.staff.profile.lastName || '')
            this.models.notes = data.sales.notes
            this.models.price = data.totalPayment
            this.models.startDate = data.sales.startDate
            this.models.endDate = data.sales.endDate
            this.models.products = data.sales.products
            this.models.paymentMethod = data.paymentMethod
            this.models.totalPayment = data.totalPayment
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    acceptPO() {
      this.$axios.post(`${api.invoice}/change/approve`, {
        id: this.models.id,
        payload: {
          id: this.models.id,
          kfsId: this.models.kfsId,
          salesId: this.models.salesId,
          invoiceCode: this.models.invoiceCode,
          totalInvoice: this.models.totalInvoice,
          voucherCode: this.models.voucherCode,
          discount: this.models.discount,
          totalPayment: this.models.totalPayment,
          paymentMethod: this.models.paymentMethod,
          cashpay: this.models.cashpay,
          change: this.models.change,
          debitNumber: this.models.debitNumber,
          phoneNumber: this.models.phoneNumber,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success approve invoice',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.getById()
          window.scrollTo(0, 0)
        })
        .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
    },
    rejectPO() {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menolak permintaan ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.post(`${api.invoice}/change/reject`, {
            id: this.models.id,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success declined invoice',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.show('modal-confirm-invoice')
              this.get()
              window.scrollTo(0, 0)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
  },
}
</script>

<style>

</style>
